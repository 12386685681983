import request from '@/utils/request'

const api_name = '/admin/vod/teacher'

export default {
    //讲师列表
    pageList(current, limit, searchObj) {
        return request({
            // 模板字符串
            // 使用json格式传递 data: searchObj
            // 使用普通格式传递 data: teacher
            url: `/admin/vod/teacher/findQueryPage/${current}/${limit}`,
            method: `post`,
            data: searchObj
        })
    },
    //删除讲师
    removeTeacherById(id) {
        return request({
            // 模板字符串
            url: `/admin/vod/teacher/remove/${id}`,
            method: `delete`
        })
    },
    //保存讲师
    saveTeacher(teacher) {
        return request({
            // 模板字符串
            url: `/admin/vod/teacher/saveTeacher`,
            method: `post`,
            data: teacher
        })
    },
    //加载讲师
    getById(id) {
        return request({
            url: `/admin/vod/teacher/getTeacher/${id}`,
            method: `get`
        })
    },
    //更新讲师
    updateTeacher(teacher) {
        return request({
            // 模板字符串
            url: `/admin/vod/teacher/updateTeacher`,
            method: `post`,
            data: teacher
        })
    },
    batchRemove(idList) {
        return request({
            url: `/admin/vod/teacher/removeBatch`,
            method: `delete`,
            data: idList
        })
    },
    //所有讲师
    list() {
        return request({
            url: `/admin/vod/teacher/findAll`,
            method: `get`
        })
    }
}
