var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "讲师名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.teacher.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.teacher, "name", $$v)
                  },
                  expression: "teacher.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入驻时间" } },
            [
              _c("el-date-picker", {
                attrs: { "value-format": "yyyy-MM-dd" },
                model: {
                  value: _vm.teacher.joinDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.teacher, "joinDate", $$v)
                  },
                  expression: "teacher.joinDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "讲师排序" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.teacher.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.teacher, "sort", $$v)
                  },
                  expression: "teacher.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "讲师头衔" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.teacher.level,
                    callback: function ($$v) {
                      _vm.$set(_vm.teacher, "level", $$v)
                    },
                    expression: "teacher.level",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "高级讲师" } }),
                  _c("el-option", { attrs: { value: 0, label: "首席讲师" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "讲师简介" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.teacher.intro,
                  callback: function ($$v) {
                    _vm.$set(_vm.teacher, "intro", $$v)
                  },
                  expression: "teacher.intro",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "讲师资历" } },
            [
              _c("el-input", {
                attrs: { rows: 10, type: "textarea" },
                model: {
                  value: _vm.teacher.career,
                  callback: function ($$v) {
                    _vm.$set(_vm.teacher, "career", $$v)
                  },
                  expression: "teacher.career",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "讲师头像" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-error": _vm.handleAvatarError,
                    action: _vm.BASE_API + "/admin/vod/file/upload",
                  },
                },
                [
                  _vm.teacher.avatar
                    ? _c("img", { attrs: { src: _vm.teacher.avatar } })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveOrUpdate()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }