var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "ccInfo" }, [
      _c("img", { attrs: { src: _vm.coursePublish.cover } }),
      _c("div", { staticClass: "main" }, [
        _c("h2", [_vm._v(_vm._s(_vm.coursePublish.title))]),
        _c("p", { staticClass: "gray" }, [
          _c("span", [
            _vm._v("共" + _vm._s(_vm.coursePublish.lessonNum) + "课时"),
          ]),
        ]),
        _c("p", [
          _c("span", [
            _vm._v(
              "所属分类：" +
                _vm._s(_vm.coursePublish.subjectParentTitle) +
                " — " +
                _vm._s(_vm.coursePublish.subjectTitle)
            ),
          ]),
        ]),
        _c("p", [_vm._v("课程讲师：" + _vm._s(_vm.coursePublish.teacherName))]),
        _c("h3", { staticClass: "red" }, [
          _vm._v("￥" + _vm._s(_vm.coursePublish.price)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticStyle: { "text-align": "center" } },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.prev()
              },
            },
          },
          [_vm._v("上一步")]
        ),
        _c(
          "el-button",
          {
            attrs: { disabled: _vm.publishBtnDisabled, type: "primary" },
            on: {
              click: function ($event) {
                return _vm.publish()
              },
            },
          },
          [_vm._v("发布课程")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }