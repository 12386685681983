var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "优惠券名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.couponInfo.couponName,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "couponName", $$v)
                  },
                  expression: "couponInfo.couponName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.couponInfo.couponType,
                    callback: function ($$v) {
                      _vm.$set(_vm.couponInfo, "couponType", $$v)
                    },
                    expression: "couponInfo.couponType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("注册卷")]),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("推荐购买卷"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发行数量" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.couponInfo.publishCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "publishCount", $$v)
                  },
                  expression: "couponInfo.publishCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "领取时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择开始日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.couponInfo.startTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "startTime", $$v)
                  },
                  expression: "couponInfo.startTime",
                },
              }),
              _vm._v(" 至 "),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择开始日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.couponInfo.endTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "endTime", $$v)
                  },
                  expression: "couponInfo.endTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "过期时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择开始日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.couponInfo.expireTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "expireTime", $$v)
                  },
                  expression: "couponInfo.expireTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播详情" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "5" },
                model: {
                  value: _vm.couponInfo.ruleDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.couponInfo, "ruleDesc", $$v)
                  },
                  expression: "couponInfo.ruleDesc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveOrUpdate } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.back } }, [_vm._v("返回")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }