var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程标题" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    " 示例：机器学习项目课：从基础到搭建项目视频课程。专业名称注意大小写",
                },
                model: {
                  value: _vm.courseInfo.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.courseInfo, "title", $$v)
                  },
                  expression: "courseInfo.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程讲师" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.courseInfo.teacherId,
                    callback: function ($$v) {
                      _vm.$set(_vm.courseInfo, "teacherId", $$v)
                    },
                    expression: "courseInfo.teacherId",
                  },
                },
                _vm._l(_vm.teacherList, function (teacher) {
                  return _c("el-option", {
                    key: teacher.id,
                    attrs: { label: teacher.name, value: teacher.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类别" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.subjectChanged },
                  model: {
                    value: _vm.courseInfo.subjectParentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.courseInfo, "subjectParentId", $$v)
                    },
                    expression: "courseInfo.subjectParentId",
                  },
                },
                _vm._l(_vm.subjectList, function (subject) {
                  return _c("el-option", {
                    key: subject.id,
                    attrs: { label: subject.title, value: subject.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.courseInfo.subjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.courseInfo, "subjectId", $$v)
                    },
                    expression: "courseInfo.subjectId",
                  },
                },
                _vm._l(_vm.subjectLevelTwoList, function (subject) {
                  return _c("el-option", {
                    key: subject.id,
                    attrs: { label: subject.title, value: subject.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "总课时" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 0,
                  "controls-position": "right",
                  placeholder: "请填写课程的总课时数",
                },
                model: {
                  value: _vm.courseInfo.lessonNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.courseInfo, "lessonNum", $$v)
                  },
                  expression: "courseInfo.lessonNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程简介" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "5" },
                model: {
                  value: _vm.courseInfo.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.courseInfo, "description", $$v)
                  },
                  expression: "courseInfo.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程封面" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "cover-uploader",
                  attrs: {
                    "show-file-list": false,
                    "on-success": _vm.handleCoverSuccess,
                    "before-upload": _vm.beforeCoverUpload,
                    "on-error": _vm.handleCoverError,
                    action: _vm.BASE_API + "/admin/vod/file/upload",
                  },
                },
                [
                  _vm.courseInfo.cover
                    ? _c("img", { attrs: { src: _vm.courseInfo.cover } })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程价格" } },
            [
              _c("el-input-number", {
                attrs: {
                  min: 0,
                  "controls-position": "right",
                  placeholder: "免费课程请设置为0元",
                },
                model: {
                  value: _vm.courseInfo.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.courseInfo, "price", $$v)
                  },
                  expression: "courseInfo.price",
                },
              }),
              _vm._v(" 元 "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.saveBtnDisabled, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAndNext()
                },
              },
            },
            [_vm._v("保存并下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }