var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "添加章节" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.chapter, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "章节标题" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.chapter.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.chapter, "title", $$v)
                  },
                  expression: "chapter.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "章节排序" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.chapter.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.chapter, "sort", $$v)
                  },
                  expression: "chapter.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveOrUpdate()
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }