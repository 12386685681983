import { render, staticRenderFns } from "./form.vue?vue&type=template&id=b61a48ce"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tony/deliverman/workspace/dev-vue-admin/50/vue-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b61a48ce')) {
      api.createRecord('b61a48ce', component.options)
    } else {
      api.reload('b61a48ce', component.options)
    }
    module.hot.accept("./form.vue?vue&type=template&id=b61a48ce", function () {
      api.rerender('b61a48ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/activity/couponInfo/form.vue"
export default component.exports