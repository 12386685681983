var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v("发布新课程"),
      ]),
      _c(
        "el-steps",
        {
          staticStyle: { "margin-bottom": "40px" },
          attrs: { active: _vm.active, "finish-status": "success", simple: "" },
        },
        [
          _c("el-step", { attrs: { title: "填写课程基本信息" } }),
          _c("el-step", { attrs: { title: "创建课程大纲" } }),
          _c("el-step", { attrs: { title: "发布课程" } }),
        ],
        1
      ),
      _vm.active === 0 ? _c("Info") : _vm._e(),
      _vm.active === 1 ? _c("Chapter") : _vm._e(),
      _vm.active === 2 || _vm.active === 3 ? _c("Publish") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }