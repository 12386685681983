var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "添加课时" },
      on: {
        close: function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.video, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课时标题" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.video.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.video, "title", $$v)
                  },
                  expression: "video.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课时排序" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.video.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.video, "sort", $$v)
                  },
                  expression: "video.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否免费" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.video.isFree,
                    callback: function ($$v) {
                      _vm.$set(_vm.video, "isFree", $$v)
                    },
                    expression: "video.isFree",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("免费")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传视频" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    "auto-upload": false,
                    "on-success": _vm.handleUploadSuccess,
                    "on-error": _vm.handleUploadError,
                    "on-exceed": _vm.handleUploadExceed,
                    "file-list": _vm.fileList,
                    limit: 1,
                    "before-remove": _vm.handleBeforeRemove,
                    "on-remove": _vm.handleOnRemove,
                    action: _vm.baseUrl + "/admin/vod/upload",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选择视频")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        disabled: _vm.uploadBtnDisabled,
                        size: "small",
                        type: "success",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitUpload()
                        },
                      },
                    },
                    [_vm._v("上传")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveOrUpdate()
                },
              },
            },
            [_vm._v("确 定（保存）")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }