var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h4", [_vm._v("优惠券信息")]),
      _c(
        "table",
        {
          staticClass: "table table-striped table-condenseda table-bordered",
          attrs: { width: "100%" },
        },
        [
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { width: "15%" } }, [_vm._v("优惠券名称")]),
              _c("td", { attrs: { width: "35%" } }, [
                _c("b", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(_vm._s(_vm.couponInfo.couponName)),
                ]),
              ]),
              _c("th", { attrs: { width: "15%" } }, [_vm._v("优惠券类型")]),
              _c("td", { attrs: { width: "35%" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.couponInfo.couponType == "REGISTER"
                        ? "注册卷"
                        : "推荐赠送卷"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("发行数量")]),
              _c("td", [_vm._v(_vm._s(_vm.couponInfo.publishCount))]),
              _c("th", [_vm._v("每人限领次数")]),
              _c("td", [_vm._v(_vm._s(_vm.couponInfo.perLimit))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("领取数量")]),
              _c("td", [_vm._v(_vm._s(_vm.couponInfo.receiveCount))]),
              _c("th", [_vm._v("使用数量")]),
              _c("td", [_vm._v(_vm._s(_vm.couponInfo.useCount))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("领取时间")]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.couponInfo.startTime) +
                    "至" +
                    _vm._s(_vm.couponInfo.endTime)
                ),
              ]),
              _c("th", [_vm._v("过期时间")]),
              _c("td", [_vm._v(_vm._s(_vm.couponInfo.expireTime))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("规则描述")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.couponInfo.ruleDesc)),
              ]),
            ]),
          ]),
        ]
      ),
      _c("h4", [_vm._v(" 优惠券发放列表 ")]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: { data: _vm.list, stripe: "", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", width: "70", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s((_vm.page - 1) * _vm.limit + scope.$index + 1) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "param.nickName", label: "用户昵称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "param.phone", label: "手机号" },
          }),
          _c("el-table-column", {
            attrs: { label: "使用状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.couponStatus == "NOT_USED"
                            ? "未使用"
                            : "已使用"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "getTime", label: "获取时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "usingTime", label: "使用时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "usedTime", label: "支付时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "expireTime", label: "过期时间" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { padding: "30px 0", "text-align": "center" },
        attrs: {
          "current-page": _vm.page,
          total: _vm.total,
          "page-size": _vm.limit,
          "page-sizes": [5, 10, 20, 30, 40, 50, 100],
          layout: "sizes, prev, pager, next, jumper, ->, total, slot",
        },
        on: { "current-change": _vm.fetchData, "size-change": _vm.changeSize },
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "0px" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.back } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }