<template>
    <!-- 添加和修改课时表单 -->
    <el-dialog :visible="dialogVisible" title="添加课时" @close="close()">
        <el-form :model="video" label-width="120px">
            <el-form-item label="课时标题">
                <el-input v-model="video.title"/>
            </el-form-item>
            <el-form-item label="课时排序">
                <el-input-number v-model="video.sort" :min="0" />
            </el-form-item>
            <el-form-item label="是否免费">
                <el-radio-group v-model="video.isFree">
                    <el-radio :label="0">免费</el-radio>
                    <el-radio :label="1">默认</el-radio>
                </el-radio-group>
            </el-form-item>

            <!-- 上传视频 -->
            <el-form-item label="上传视频">
                <el-upload
                    ref="upload"
                    :auto-upload="false"
                    :on-success="handleUploadSuccess"
                    :on-error="handleUploadError"
                    :on-exceed="handleUploadExceed"
                    :file-list="fileList"
                    :limit="1"
                    :before-remove="handleBeforeRemove"
                    :on-remove="handleOnRemove"
                    :action="baseUrl+'/admin/vod/upload'">
                    <el-button slot="trigger" size="small" type="primary">选择视频</el-button>
                    <el-button
                        :disabled="uploadBtnDisabled"
                        style="margin-left: 10px;"
                        size="small"
                        type="success"
                        @click="submitUpload()">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close()">取 消</el-button>
            <el-button type="primary" @click="saveOrUpdate()">确 定（保存）</el-button>
        </div>
    </el-dialog>
</template>

<script>
import videoApi from '@/api/vod/video'
import vodApi from '@/api/vod/vod'
export default {

    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_API,
            dialogVisible: false,
            video: {
                sort: 0,
                free: false
            },
            fileList: [], // 上传文件列表
            uploadBtnDisabled: false
        }
    },

    methods: {
        open(chapterId, videoId) {
            this.dialogVisible = true
            this.video.chapterId = chapterId
            if (videoId) {
                videoApi.getById(videoId).then(response => {
                    this.video = response.data
                    // 回显
                    if (this.video.videoOriginalName) {
                        this.fileList = [{ 'name': this.video.videoOriginalName }]
                    }
                })
            }
        },

        close() {
            this.dialogVisible = false
            // 重置表单
            this.resetForm()
        },

        resetForm() {
            this.video = {
                sort: 0,
                free: false
            }

            this.fileList = [] // 重置视频上传列表
        },

        saveOrUpdate() {
            if (!this.video.id) {
                this.save()
            } else {
                this.update()
            }
        },

        save() {
            console.log('video save, this.$parent.$parent.courseId: ', this.$parent.$parent.courseId);
            this.video.courseId = this.$parent.$parent.courseId
            videoApi.save(this.video).then(response => {
                this.$message.success(response.message)
                // 关闭组件
                this.close()
                // 刷新列表
                this.$parent.fetchNodeList()
            })
        },

        update() {
            videoApi.updateById(this.video).then(response => {
                this.$message.success(response.message)
                // 关闭组件
                this.close()
                // 刷新列表
                this.$parent.fetchNodeList()
            })
        },

        // 上传多于一个视频
        handleUploadExceed(files, fileList) {
            this.$message.warning('想要重新上传视频，请先删除已上传的视频')
        },

        // 上传
        submitUpload() {
            console.log('submitUpload <----------------');
            this.uploadBtnDisabled = true
            this.$refs['upload'].submit() // 提交上传请求
        },

        // 视频上传成功的回调
        handleUploadSuccess(response, file, fileList) {
            console.log('handleUploadSuccess <---------------- begin');
            this.uploadBtnDisabled = false
            this.video.videoSourceId = response.data
            this.video.videoOriginalName = file.name
            console.log('handleUploadSuccess <---------------- end',  this.video.videoSourceId, file.name);
        },

        // 失败回调
        handleUploadError() {
            this.uploadBtnDisabled = false
            this.$message.error('上传失败2')
        },

        // 删除视频文件确认
        handleBeforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },

        // 执行视频文件的删除
        handleOnRemove(file, fileList) {
            if (!this.video.videoSourceId) {
                return
            }
        }
    }
}
</script>
