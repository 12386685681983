var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "operate-container", attrs: { shadow: "never" } },
        [
          _c("i", {
            staticClass: "el-icon-tickets",
            staticStyle: { "margin-top": "5px" },
          }),
          _c("span", { staticStyle: { "margin-top": "5px" } }, [
            _vm._v("数据列表"),
          ]),
          _c(
            "el-button",
            {
              staticClass: "btn-add",
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.add()
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "数据正在加载......",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", width: "70", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s((_vm.page - 1) * _vm.limit + scope.$index + 1) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "couponName", label: "购物券名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "couponType", label: "购物券类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.couponType == "REGISTER"
                            ? "注册卷"
                            : "推荐赠送卷"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "规则" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s("现金卷：" + scope.row.amount + "元") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "使用范围 " } }, [
            _vm._v(" 所有商品 "),
          ]),
          _c("el-table-column", {
            attrs: { prop: "publishCount", label: "发行数量" },
          }),
          _c("el-table-column", {
            attrs: { prop: "expireTime", label: "过期时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/activity/couponInfo/edit/" + scope.row.id,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "mini", type: "text" } },
                          [_vm._v("修改")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.removeDataById(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/activity/couponInfo/show/" + scope.row.id,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "mini", type: "text" } },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { padding: "30px 0", "text-align": "center" },
        attrs: {
          "current-page": _vm.page,
          total: _vm.total,
          "page-size": _vm.limit,
          "page-sizes": [5, 10, 20, 30, 40, 50, 100],
          layout: "sizes, prev, pager, next, jumper, ->, total, slot",
        },
        on: { "current-change": _vm.fetchData, "size-change": _vm.changeSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }