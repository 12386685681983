var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.addChapter()
                },
              },
            },
            [_vm._v("添加章节")]
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "chapterList" },
        _vm._l(_vm.chapterList, function (chapter) {
          return _c("li", { key: chapter.id }, [
            _c("p", [
              _vm._v(" " + _vm._s(chapter.title) + " "),
              _c(
                "span",
                { staticClass: "acts" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.addVideo(chapter.id)
                        },
                      },
                    },
                    [_vm._v("添加课时")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.editChapter(chapter.id)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.removeChapterById(chapter.id)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "ul",
              { staticClass: "chapterList videoList" },
              _vm._l(chapter.children, function (video) {
                return _c("li", { key: video.id }, [
                  _c(
                    "p",
                    [
                      _vm._v(" " + _vm._s(video.title) + " "),
                      !video.videoSourceId
                        ? _c(
                            "el-tag",
                            { attrs: { size: "mini", type: "danger" } },
                            [_vm._v(" " + _vm._s("尚未上传视频") + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        { staticClass: "acts" },
                        [
                          video.isFree
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "success" } },
                                [_vm._v(_vm._s("免费观看"))]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.editVideo(chapter.id, video.id)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeVideoById(video.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ])
        }),
        0
      ),
      _c("chapter-form", { ref: "chapterForm" }),
      _c("video-form", { ref: "videoForm" }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.prev()
                },
              },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.next()
                },
              },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }