var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "operate-container", attrs: { shadow: "never" } },
        [
          _c("i", {
            staticClass: "el-icon-tickets",
            staticStyle: { "margin-top": "5px" },
          }),
          _c("span", { staticStyle: { "margin-top": "5px" } }, [
            _vm._v("数据列表"),
          ]),
          _c(
            "el-button",
            {
              staticClass: "btn-add",
              staticStyle: { "margin-left": "10px" },
              attrs: { size: "mini" },
              on: { click: _vm.remove },
            },
            [_vm._v("删除菜单")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-add",
              attrs: { size: "mini" },
              on: { click: _vm.syncMenu },
            },
            [_vm._v("同步菜单")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-add",
              attrs: { size: "mini" },
              on: { click: _vm.add },
            },
            [_vm._v("添 加")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.list,
            "row-key": "id",
            border: "",
            "default-expand-all": "",
            "tree-props": { children: "children" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", prop: "name", width: "350" },
          }),
          _c("el-table-column", {
            attrs: { label: "类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.type == "view"
                            ? "链接"
                            : scope.row.type == "click"
                            ? "事件"
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "菜单URL", prop: "url" } }),
          _c("el-table-column", {
            attrs: { label: "菜单KEY", prop: "meunKey", width: "130" },
          }),
          _c("el-table-column", {
            attrs: { label: "排序号", prop: "sort", width: "70" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "170", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.parentId > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        )
                      : _vm._e(),
                    scope.row.parentId > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.removeDataById(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加/修改",
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "flashPromotionForm",
              staticStyle: { "padding-right": "40px" },
              attrs: { "label-width": "150px", size: "small" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择一级菜单" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.menu.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.menu, "parentId", $$v)
                        },
                        expression: "menu.parentId",
                      },
                    },
                    _vm._l(_vm.list, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.menu.parentId == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.liveCourseChanged },
                          model: {
                            value: _vm.menu.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.menu, "name", $$v)
                            },
                            expression: "menu.name",
                          },
                        },
                        _vm._l(_vm.liveCourseList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.courseName, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menu.parentId == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.subjectChanged },
                          model: {
                            value: _vm.menu.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.menu, "name", $$v)
                            },
                            expression: "menu.name",
                          },
                        },
                        _vm._l(_vm.subjectList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menu.parentId == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.menu.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.menu, "name", $$v)
                          },
                          expression: "menu.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.menu.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.menu, "type", $$v)
                        },
                        expression: "menu.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "view" } }, [
                        _vm._v("链接"),
                      ]),
                      _c("el-radio", { attrs: { label: "click" } }, [
                        _vm._v("事件"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.menu.type == "view"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.menu.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.menu, "url", $$v)
                          },
                          expression: "menu.url",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menu.type == "click"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "菜单KEY" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.menu.meunKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.menu, "meunKey", $$v)
                          },
                          expression: "menu.meunKey",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.menu.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.menu, "sort", $$v)
                      },
                      expression: "menu.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.saveOrUpdate()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }